
import { defineComponent, ref } from "vue"
import { useRouter } from "vue-router"

export default defineComponent({
	setup() {
		const router = useRouter();
		const LastPage = () => {
			router.back();
		}
		return {
			LastPage
		}
	},
})
