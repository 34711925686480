import { Store } from "@/store";
import axios from "axios";
import { BaseUrl, IsDebug, WechatAppID } from "./Const";
import { CSVData, ExcelData, ResponseData } from "@/models//Interface";
import dayjs from "dayjs";
import ExcelJS from "exceljs";

axios.defaults.headers.post["Content-Type"] = "application/json";

export const HttpHelper = axios.create({
	baseURL: BaseUrl,
	timeout: 10 * 1000,
	transformRequest: [(data, headers) => {
		const store = Store();
		headers!["user-token"] = store.token;
		headers!["platform"] = store.platform;
		return JSON.stringify(data);
	}],
});

export class Utils {

	//#region 获取浏览器信息
	/**获取浏览器信息 */
	static GetBrowserInfo() {
		var agent = navigator.userAgent.toLowerCase();

		var regStr_ie = /msie [\d.]+;/gi;
		var regStr_ff = /firefox\/[\d.]+/gi
		var regStr_chrome = /chrome\/[\d.]+/gi;
		var regStr_saf = /safari\/[\d.]+/gi;

		// 微信内置浏览器
		if (/micromessenger/i.test(agent))
			return "Wechat";

		//IE
		if (agent.indexOf("msie") > 0)
			// return agent.match(regStr_ie);
			return "IE";

		//Firefox
		if (agent.indexOf("firefox") > 0)
			// return agent.match(regStr_ff);
			return "FireFox";

		//Safari
		if (agent.indexOf("safari") > 0 && agent.indexOf("chrome") < 0)
			// return agent.match(regStr_saf);
			return "Safari";

		//Chrome
		if (agent.indexOf("chrome") > 0)
			// return agent.match(regStr_chrome);
			return "Chrome";

		return "Unknow";
	}
	//#endregion 获取浏览器信息

	//#region 获取平台信息
	static GetPlatformInfo() {
		let info = this.GetBrowserInfo();
		switch (info) {
			case "Wechat":
				return "Wechat";
			case "IE":
			case "FireFox":
			case "Chrome":
			case "Safari":
			case "Unknow":
				return "Browser";
		}
		return "Browser";
	}
	//#endregion 获取平台信息

	//#region 格式化字符串
	/**格式化字符串 */
	static StringFormat(text: string, ...params: string[]) {
		for (let i = 0; i < params.length; i++) {
			text = text.replaceAll(`{${i}}`, params[i]);
		}
		return text;
	}
	//#endregion 格式化字符串

	//#region 检测所有字符串是否为空
	/**
	 * 检测所有字符串是否为空
	 * @param text 要检查的所有字符串
	 * @returns 为空的第一个索引，-1为无空字符串
	 */
	static CheckStringsEmpty(...text: string[]) {
		for (let i = 0; i < text.length; i++) {
			if (!text[i] || text[i].trim() == "")
				return i;
		}
		return -1;
	}
	//#endregion 检测所有字符串是否为空

	//#region 获取当前地址，不包含参数
	/**获取当前地址，不包含参数 */
	static GetUrl() {
		let url = location.href;
		let temp = url.split("?");
		return temp[0];
	}
	//#endregion 获取当前地址，不包含参数

	//#region 转换时间
	/**转换时间示例：YYYY年MM月DD日 HH时mm分ss秒 */
	static ConvertTime(time: string, format: string) {
		let date = dayjs(time);
		return date.format(format);
	}
	//#endregion 转换时间

}

declare var wx: any;

interface WechatConfig {
	nonceStr: string;
	signature: string;
	timestamp: number;
}

const WechatConfig = "wechat/wechat-config";

export class WechatUtils {
	/**
	 * 获取JSAPI设置
	 * @returns 
	 */
	static async WechatJSAPIConfig() {
		return new Promise(async (resolve, reject) => {
			if (Utils.GetBrowserInfo() == "Wechat" || IsDebug) {
				let url = location.href;
				let response = await HttpHelper.post<ResponseData<WechatConfig>>(WechatConfig, { url });
				if (!response.data.success) {
					reject();
				}

				let data = response.data.data;
				wx.config({
					debug: false,
					appId: WechatAppID,
					timestamp: data.timestamp,
					nonceStr: data.nonceStr,
					signature: data.signature,
					jsApiList: ["chooseWXPay"]
				});

				wx.ready(resolve("success"));
			} else {
				reject();
			}
		});

	}
}