import { createApp } from 'vue'
import App from '@/MemberPage/App.vue'
import { router } from '@/MemberPage/router'
import { createPinia } from "pinia";
import Vant from 'vant';

import 'dayjs/locale/zh-cn';
import dayjs from 'dayjs';

// import "vant/lib/index.css";
// import "cropperjs/dis/cropper.min.css"

const app = createApp(App);
app.use(createPinia()).use(router);
app.use(Vant);
app.mount('#app');

var customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.locale('zh-cn');
dayjs.extend(customParseFormat);