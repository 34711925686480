import { IsDebug, Platform, UserToken } from '@/models/Const';
import { IUser } from '@/models/User'
import { defineStore } from "pinia";

export const Store = defineStore("store", {
	state: () => {
		return {
			token: IsDebug ? UserToken : "",
			platform: IsDebug ? Platform : "",
			user: <IUser | null>null,
			menuRouter: "Member"
		}
	}
});
