import { IsDebug } from "@/models/Const";
import { Utils } from "@/models/Utils";
import { Store } from "@/store";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"

export const RouterName = {
	HomePage: "Home",
	Login: {
		Normal: "Login",
		Wechat: "Login-Wechat"
	},
	Register: "Register",
	Pay: "Pay",
	UserInfo: {
		Info: "UserInfo",
		Party: "Party"
	},
	League: {
		LeagueList: "LeagueList",
		LeagueInfo: "LeagueInfo",
		LeagueSignIn: "LeagueSignIn",
		TeamMate: "TeamMate",
		MyLicence: "MyLicence"
	}
}

const routes: Array<RouteRecordRaw> = [
	{ path: "/home", name: RouterName.HomePage, component: () => import("@/MemberPage/components/User/UserHome.vue") },

	{ path: "/", name: RouterName.Login.Normal, component: () => import("@/MemberPage/views/Login.vue") },
	{ path: "/login-wechat", name: RouterName.Login.Wechat, component: () => import("@/MemberPage/views/LoginWechat.vue") },

	{ path: "/user-info", name: RouterName.UserInfo.Info, component: () => import("@/MemberPage/components/User/UserInfo.vue") },
	{ path: "/party", name: RouterName.UserInfo.Party, component: () => import("@/MemberPage/components/PartySelect.vue") },

	{ path: "/register", name: RouterName.Register, component: () => import("@/MemberPage/views/Register.vue") },
	{ path: "/pay", name: RouterName.Pay, component: () => import("@/MemberPage/views/Pay.vue") },

	{ path: "/league-list", name: RouterName.League.LeagueList, component: () => import("@/MemberPage/components/League/LeagueList.vue") },
	{ path: "/league-info", name: RouterName.League.LeagueInfo, component: () => import("@/MemberPage/components/League/LeagueInfo.vue") },
	{ path: "/league-signin", name: RouterName.League.LeagueSignIn, component: () => import("@/MemberPage/components/League/LeagueSignIn.vue") },
	{ path: "/team-mate", name: RouterName.League.TeamMate, component: () => import("@/MemberPage/components/League/TeamMate.vue") },
	{ path: "/licence", name: RouterName.League.MyLicence, component: () => import("@/MemberPage/components/League/LeagueLicence.vue") },
]

export const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

router.beforeEach((to, from, next) => {
	// console.log("index router");
	// console.log(to);

	const store = Store();
	if (to.name == RouterName.Login.Normal) {
		next();
	} else if (Utils.GetBrowserInfo() != "Wechat" && !IsDebug) {
		next({ name: RouterName.Login.Normal });
	} else if (to.name == RouterName.Login.Wechat) {
		next();
	} else if (Utils.CheckStringsEmpty(store.token) >= 0 && to.name != RouterName.Login.Normal) {
		next({ name: RouterName.Login.Normal });
	} else {
		next();
	}
});